<template>
  <div class="component-picker" :class="side">
    <div class="componenting" v-if="needsComponent">
      <component-picker
        :c="c"
        :config="config"
        @selected="selected"
      ></component-picker>
    </div>
    <div v-else>
      <h3>
        <i class="fas fa-chevron-left" @click="selectComponent"></i>Configuring
        {{ c.component }}
        <i
          class="fas fa-arrow-down pull-right"
          @click="copyComponent('down')"
        ></i>
        <i
          class="fas fa-arrow-right pull-right"
          @click="copyComponent('right')"
        ></i>
      </h3>
      <component :is="c.component" :config="c.config" mode="configuring" />
      <br />
      {{ c }}
    </div>
  </div>
</template>

<script>
import DashboardGraph from "@/components/dashboards/DashboardGraph.vue";
import DashboardGraphSql from "@/components/dashboards/DashboardGraphSql.vue";
import DashboardGraphLocation from "@/components/dashboards/DashboardGraphLocation.vue";
import ProductionValue from "@/components/dashboards/ProductionValue.vue";
import DashboardParent from "@/components/dashboards/DashboardParent.vue";
import DashboardSection from "@/components/dashboards/DashboardSection.vue";
import DashboardStatus from "@/components/dashboards/DashboardStatus.vue";
import DashboardGauge from "@/components/dashboards/DashboardGauge.vue";
import DashboardSqlGauge from "@/components/dashboards/DashboardSqlGauge.vue";
import DashboardLean from "@/components/dashboards/DashboardLean.vue";
import DashboardTimeHistory from "@/components/dashboards/DashboardTimeHistory.vue";
import DashboardProductionGoal from "@/components/dashboards/DashboardProductionGoal.vue";
import DashboardOrderSummary from "@/components/dashboards/DashboardOrderSummary.vue";
import DashboardTable from "@/components/dashboards/DashboardTable.vue";
import ComponentPicker from "./ComponentPicker.vue";
import FreeText from "@/components/dashboards/FreeText.vue";
import _ from "lodash";
import SpecialDashboardComponent from "@/components/dashboards/SpecialDashboardComponent";
export default {
  props: {
    c: {
      required: true,
    },
    config: {
      required: true,
    },
  },
  data() {
    return {
      selectingComponent: false,
    };
  },
  emits: ["activate"],
  methods: {
    copyComponent(dir) {
      let compCopy = _.cloneDeep(this.c);
      this.$emit("activate", this.c);
      compCopy.id = _.uniqueId("copy-");
      if (dir === "down") {
        compCopy.pos.y += compCopy.pos.h;
      }
      if (dir === "right") {
        compCopy.pos.x += compCopy.pos.w;
      }
      this.config.widgets.push(compCopy);
      this.$emit("activate", compCopy);
    },
    selectComponent() {
      this.selectingComponent = true;
    },
    selected() {
      this.selectingComponent = false;
      //this.c.config = {}; SHOULD WE DO THIS?
    },
  },
  computed: {
    side() {
      if (this.c.pos.x >= this.config.size.x / 2) {
        return "left";
      }
      return "right";
    },
    needsComponent() {
      return this.c.component === null || this.selectingComponent;
    },
  },
  components: {
    DashboardGraph,
    DashboardGraphSql,
    DashboardGraphLocation,
    FreeText,
    ProductionValue,
    DashboardParent,
    DashboardSection,
    ComponentPicker,
    DashboardStatus,
    DashboardGauge,
    DashboardSqlGauge,
    DashboardLean,
    DashboardTimeHistory,
    DashboardProductionGoal,
    DashboardOrderSummary,
    DashboardTable,
    SpecialDashboardComponent,
  },
};
</script>

<style>
</style>