<template>
  <div>
    <h1>Select component type</h1>
    <button
      @click="selectComp('dashboard-section')"
      class="form-control"
      :class="{ 'btn-success': c.component === 'dashboard-section' }"
    >
      Section
    </button>
    <p class="component-description">
      Section, usable for backgrounds, borders and images.
    </p>
    <button
      @click="selectComp('free-text')"
      class="form-control"
      :class="{ 'btn-success': c.component === 'free-text' }"
    >
      Free text
    </button>
    <p class="component-description">Configurable, non-html text.</p>
    <button
      @click="selectComp('dashboard-status')"
      class="form-control"
      :class="{ 'btn-success': c.component === 'dashboard-status' }"
    >
      Machine status
    </button>
    <p class="component-description">
      Machine status indicator, with/without additional information related to
      status, with/without action handlers.
    </p>
    <button
      @click="selectComp('production-value')"
      class="form-control"
      :class="{ 'btn-success': c.component === 'production-value' }"
    >
      Production value
    </button>
    <p class="component-description">
      Production values from machines, live updated from dataset with different
      styling options.
    </p>
    <button
      @click="selectComp('dashboard-graph')"
      class="form-control"
      :class="{ 'btn-success': c.component === 'dashboard-graph' }"
    >
      Machine Graph
    </button>
    <p class="component-description">
      Graphs with their data taken from a singular machine.
    </p>
    <button
      @click="selectComp('dashboard-graph-location')"
      class="form-control"
      :class="{ 'btn-success': c.component === 'dashboard-graph-location' }"
    >
      Location Graph
    </button>
    <p class="component-description">
      Graphs with their data taken from a location (category/zone/department).
    </p>
    <button
      @click="selectComp('dashboard-graph-sql')"
      class="form-control"
      :class="{ 'btn-success': c.component === 'dashboard-graph-sql' }"
    >
      Sql Graph
    </button>
    <p class="component-description">
      Graphs with their data taken from a user defined SQL query.
    </p>
    <button
      @click="selectComp('dashboard-time-history')"
      class="form-control"
      :class="{ 'btn-success': c.component === 'dashboard-time-history' }"
    >
      Dashboard time history
    </button>
    <p class="component-description">
      Time history with their data taken from a location
      (category/zone/department).
    </p>
    <button
      @click="selectComp('dashboard-gauge')"
      class="form-control"
      :class="{ 'btn-success': c.component === 'dashboard-gauge' }"
    >
      Production value Gauge
    </button>
    <p class="component-description">Sql value gauge.</p>
      <button
      @click="selectComp('dashboard-sql-gauge')"
      class="form-control"
      :class="{ 'btn-success': c.component === 'dashboard-sql-gauge' }"
    >
      Sql value Gauge
    </button>
    <p class="component-description">Sql value gauge.</p>
    <button
      @click="selectComp('dashboard-production-goal')"
      class="form-control"
      :class="{ 'btn-success': c.component === 'dashboard-production-goal' }"
    >
      Production goal (WIP)
    </button>
    <p class="component-description">Production goal (WIP).</p>
    
    <button
      @click="selectComp('dashboard-order-summary')"
      class="form-control"
      :class="{ 'btn-success': c.component === 'dashboard-order-summary' }"
    >
      dashboard-order-summary (WIP)
    </button>
    <p class="component-description">Dashboard order summary (WIP).</p>
      <button
      @click="selectComp('dashboard-table')"
      class="form-control"
      :class="{ 'btn-success': c.component === 'dashboard-table' }"
    >
      dashboard-table (WIP)
    </button>
    <p class="component-description">Dashboard table (WIP).</p>
      <button
      @click="selectComp('dashboard-parent')"
      class="form-control"
      :class="{ 'btn-success': c.component === 'dashboard-parent' }"
    >
      dashboard-parent (WIP)
    </button>
    <p class="component-description">Dashboard table (WIP).</p>
     <button
      @click="selectComp('special-dashboard-component')"
      class="form-control"
      :class="{ 'btn-success': c.component === 'special-dashboard-component' }"
    >
      Special components 
    </button>
    <p class="component-description">Special components that may require specific configuration to function (WIP).</p>
    <!--
        <button @click="selectComp('dashboard-lean')" class="form-control" :class="{'btn-success':c.component==='dashboard-lean'}">Dashboard lean</button>
      <p class="component-description">
          Dashboard lean
          <span class="warning" style="color:red;">NOT IMPLEMENTED</span>
      </p>
      -->
  </div>
</template>

<script>
export default {
  props: {
    c: {
      required: true,
    },
    config: {
      required: true,
    },
  },
  emits: ["selected"],
  methods: {
    selectComp(comp) {
      this.c.component = comp;
      this.c.config = {
        style: this.config.defaultStyle,
        verticalAlign: this.config.defaultVertical,
        horizontalAlign: this.config.defaultHorizontal,
        backgroundEnabled: false,
      };
      console.log(this.c, this.config.defaultStyle);
      this.$emit("selected");
    },
  },
  computed: {
    side() {
      if (this.c.pos.x >= this.config.size.x / 2) {
        return "left";
      }
      return "right";
    },
  },
};
</script>

<style>
</style>